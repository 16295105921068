<template>
  <b-container class="p-0 g-0">
    <!-- registration thank you message-->
    <b-row v-if="isNewRegistration">
      <b-col md="12">
        <h1 class="m-0 my-2">
          Thank you!
        </h1>
        <b-card class="mt-1 mb-4">
          <b-row>
            <b-col>
              <div class="registration-message">
                Welcome to
                <b-img
                  :src="logoTiny"
                  width="24px"
                  class="m-0"
                />
                Daily Survivor Pool, the ultimate destination for sports trivia enthusiasts! Get ready to showcase your sports knowledge and compete with fellow sports fans from across the world for a chance to win big - $25 CAD is up for grabs for the ultimate survivor who can answer the most sports-themed questions correctly. Let the games begin!
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- current question -->
    <b-row>
      <b-col md="12">
        <div class="mb-1">
          <h3 class="m-0">
            Today's Question
            <b-badge
              v-if="currentQuestion.id"
              pill
              variant="secondary"
              class="font-small"
            >
              #{{ currentQuestion.id }}
            </b-badge>
          </h3>
          <div class="pool-header">
            <h4
              v-if="(currentQuestion.current_cohorts || currentQuestion.joinable_cohorts)"
              class="text-muted inline-block pr-1"
            >
              {{ currentQuestion.current_cohorts.length > 0 ? currentQuestion.current_cohorts[0].cohort_name : '' }}
              <b-badge
                v-if="currentQuestion.current_cohorts.length > 0"
                pill
                variant="secondary"
                class="font-small"
              >
                Round {{ currentQuestion.current_cohorts[0].progress + 1 }}
              </b-badge>
            </h4>
            <span
              v-if="(currentQuestion.current_cohorts || currentQuestion.open_date)"
              class="text-muted"
            >
              {{ currentQuestion.current_cohorts.length > 0 ? convertToLocalDate(currentQuestion.current_cohorts[0].date_started) : convertToLocalDate(currentQuestion.open_date) }}
            </span>
          </div>
        </div>
        <b-card class="my-1 question-card">
          <!-- date/pool/prize -->
          <div class="question-header">
            <b-row v-if="currentQuestion.open_date">
              <b-col
                cols="4"
                class="text-left"
              >
                <span class="text-muted d-block">Entries</span>
                {{ currentQuestion.current_cohorts.length > 0 ? currentQuestion.current_cohorts[0].starting_count : (currentPool.starting_count || currentPoolPublic.starting_count || currentQuestion.total_responses || '0') }}
              </b-col>
              <b-col
                cols="4"
                class="text-center"
              >
                <span class="text-muted d-block">Survivors</span>
                {{ currentQuestion.current_cohorts.length > 0 ? currentQuestion.current_cohorts[0].current_count : (currentPool.current_count || currentPoolPublic.current_count || '0') }}
              </b-col>
              <b-col
                cols="4"
                class="text-right"
              >
                <span class="text-muted d-block">Prize</span>
                <div v-if="(currentPool.prize_total_pennies > 0 || currentPoolPublic.prize_total_pennies > 0)">
                  {{ getReadableDollarAmount(currentPool.prize_total_pennies || currentPoolPublic.prize_total_pennies) }}
                </div>
                <div v-else>
                  <span class="text-success">$25.00</span> <!-- TODO: remove hardcoded prize -->
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- question text -->
          <b-card-text class="question-text text-center">
            <h4>{{ currentQuestion.text }}</h4>
          </b-card-text>

          <!-- question actions -->
          <div class="question-footer">
            <!-- if user has not yet responded -->
            <b-row
              v-if="currentQuestion.can_respond"
              class="button-row"
            >
              <b-col
                cols="12"
                class="text-center"
              >
                <h4>To enter the current pool, select your answer:</h4>
              </b-col>
              <b-col
                cols="6"
                class="col-6"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-block text-uppercase"
                  @click="answerQuestion(true)"
                >
                  Yes
                </button>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="btn btn-primary btn-block text-uppercase"
                  @click="answerQuestion(false)"
                >
                  No
                </button>
              </b-col>
            </b-row>

            <!-- if user has already responded -->
            <b-row
              v-if="currentQuestion.already_responded"
              class="button-row"
            >
              <b-col
                md="12"
                class="text-center"
              >
                <h4>
                  You chose
                </h4>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="btn btn-block text-uppercase cursor-default"
                  :class="currentQuestion.already_responded_response ? 'btn-primary' : 'btn-primary transparent'"
                >
                  Yes
                </button>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="btn btn-block text-uppercase cursor-default"
                  :class="!currentQuestion.already_responded_response ? 'btn-primary' : 'btn-primary transparent'"
                >
                  No
                </button>
              </b-col>
              <b-col
                md="12"
                class="text-center mt-2"
              >
                <h5>
                  Thank you for entering. Good luck!
                </h5>
              </b-col>
            </b-row>

            <!-- if user did not respond and time is up -->
            <b-row
              v-if="questionMissedWindow"
              class="button-row"
            >
              <b-col
                md="12"
                class="text-center my-1"
              >
                <h4>You missed your chance to enter!</h4>
                Check back for the next question.
              </b-col>
            </b-row>

            <!-- countdown to cutoff -->
            <b-row v-if="cutoffCountdownTime > 0">
              <b-col
                md="12"
                class="text-center my-1"
              >
                <countdown
                  :time="cutoffCountdownTime"
                  :emit-events="true"
                  @end="refreshForNewQuestion"
                >
                  <template slot-scope="props">
                    Closing in
                    {{ props.days ? `${props.days}d` : '' }}
                    {{ props.hours ? `${props.hours}h` : '' }}
                    {{ props.minutes ? `${props.minutes}m` : '' }}
                    {{ props.seconds ? `${props.seconds}s` : '' }}
                  </template>
                </countdown>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- promotional copy -->
    <b-row
      v-if="promotionalCopy"
      class="mt-2 mb-2"
    >
      <b-col md="12">
        <b-img
          :src="logoRound"
          center
          fluid
          width="300px"
          alt="Daily Survivor Pool"
          class="mb-1"
        />
        <h3>Welcome to Daily Survivor Pool!</h3>
        <b-card class="mt-1">
          <b-card-text class="m-0">
            {{ promotionalCopy }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <!-- next question countdown -->
    <b-row v-if="nextCountdownTime > 0">
      <b-col
        cols="12"
        class="my-2 text-center"
      >
        <h4>
          The next question is in
          <br>
          <countdown
            :time="nextCountdownTime"
            :emit-events="true"
            @end="refreshForNewQuestion"
          >
            <template slot-scope="props">
              {{ props.days ? `${props.days}d` : '' }}
              {{ props.hours ? `${props.hours}h` : '' }}
              {{ props.minutes ? `${props.minutes}m` : '' }}
              {{ props.seconds ? `${props.seconds}s` : '' }}
            </template>
          </countdown>
        </h4>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BBadge, BCard, BCardText,
  BImg,
  // BTable,
} from 'bootstrap-vue'
import moment from 'moment'

import { isUserLoggedIn } from '@/auth/utils'
import HomeBackground from '@/assets/images/backgrounds/home.png'
import LogoRound from '@/assets/images/logo/logo-round.png'
import LogoTiny from '@/assets/images/logo/logo-tiny.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardText,
    BImg,
    // BTable,
  },
  data() {
    return {
      logoRound: LogoRound,
      logoTiny: LogoTiny,
      isNewRegistration: false,
    }
  },
  computed: {
    questionMissedWindow() {
      return !this.currentQuestion.error && (this.currentQuestion.text && !this.currentQuestion.already_responded && !this.currentQuestion.can_respond)
    },
    promotionalCopy() {
      return this.$store.getters['misc/getPromotionalCopy']
    },
    /* notifications() {
      return this.$store.getters['misc/getUnseenNotifs']
    }, */
    currentQuestion() {
      return this.$store.getters['questions/getCurrentQuestion']
    },
    cutoffCountdownTime() {
      const currentDate = new Date()
      const cutoffDate = new Date(this.convertToLocalTime(this.currentQuestion.cutoff_date))
      const countdown = cutoffDate.getTime() - currentDate.getTime()
      return countdown
    },
    nextCountdownTime() {
      const currentDate = new Date()
      const nextDate = new Date(this.convertToLocalTime(this.currentQuestion.next_question_open_date))
      const countdown = nextDate.getTime() - currentDate.getTime()
      return countdown
    },
    /* lastResponse() {
      return this.$store.getters['questions/getLastResponse']
    }, */
    poolsOngoing() {
      return this.$store.getters['pools/getAllOngoingPools']
    },
    currentPool() {
      return this.poolsOngoing[0] || {}
    },
    currentPoolPublic() {
      const publicPools = this.$store.getters['pools/getAllPublicPools']
      return publicPools[0] || {}
    },
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.$router.push('/login')
    }

    // check if user has just registered
    const newUser = localStorage.getItem('newUser')
    if (newUser) {
      // indicates user landed here through new account registration
      this.isNewRegistration = true
      // reset the new user flag, so message isn't shown again
      localStorage.removeItem('newUser')
    }

    this.$store.dispatch('misc/fetchPromotionalCopy')
    /* this.$store.dispatch('misc/fetchUnseenNotifs') */
    this.refreshForNewQuestion()
  },
  methods: {
    /* DATA RETRIEVAL */

    refreshForNewQuestion() {
      this.$store.dispatch('questions/fetchCurrentQuestion')
      // this.$store.dispatch('questions/fetchLastResponse')
      this.$store.dispatch('pools/fetchAllOngoingPools')
      this.$store.dispatch('pools/fetchAllPublicPools')
    },

    /* QUESTION ACTIONS */

    answerQuestion(choice) {
      this.$store.dispatch('questions/setCurrentQuestionAnswer', choice)
        .then(response => {
          console.log('answerQuestion', response)

          // update currentQuestion
          this.refreshForNewQuestion()
        })
    },

    /* MISCELLANEOUS */

    getReadableDollarAmount(penniesStr) {
      let formattedDollarAmount = '$0'
      if (penniesStr > 0) {
        formattedDollarAmount = (penniesStr / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) // TODO get local currency
      }
      return formattedDollarAmount
    },
    convertToLocalDate(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD')
      return convertedDate
    },
    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style scoped>
.inline-block {
  display: inline-block;
}
.transparent {
  opacity: 0.2;
}
.registration-message {
  font-size: 1.3em;
  font-weight: 500;
  text-align: justify;
}
</style>
